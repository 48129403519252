<template>
    <div id="clinic-dashboard-patients">
        <vs-row vs-type="flex">
            <PatientCountWidget :patientCountData="patientCountData" :loadingChart="loadingCharts.patientCount"
                :isFetchingMainFilters="isFetchingMainFilters" />
        </vs-row>

        <vs-row vs-type="flex">
            <div :style="{ width: '100%' }">
                <FeedbackWidget slug="insights-clinic-dashboard-feedback" :clinicId="selectedClinic" />
            </div>
        </vs-row>

        <vs-row vs-type="grid" class="grids-2">
            <div class="widget-container chart-widget" vs-justify="center"
                :style="{ justifyContent: 'flex-start', width: '100%', minHeight: '280px', paddingBottom: '10px' }">
                <vs-row vs-justify="space-between">
                    <div class="widget-title">Time Between Appointments

                        <QuestionTooltip>
                            <template slot="header">Time Between Appointments</template>
                            <template slot="body">Amount of time that has elapsed since the last appointment for all of
                                your previous patients</template>
                        </QuestionTooltip>
                    </div>
                    <div class="widget-options flex insight-widget-header" style="justify-content: flex-end;">
                        <!-- <SearchAI title="" :btnIcon="true" /> -->
                        <!-- <vs-button :disabled="loadingCharts.timeAppointmentsData" v-if="timeAppointmentsData.labels.length" style="padding:5px 15px;" @click="exportTimeAppointmentsData(timeAppointmentsData)">
                            Export
                        </vs-button> -->
                        <div class="dashboard-select full-label" style="width: 200px;">
                            <label class="select-label">Products</label>
                            <el-select popper-class="popper-select" v-model="selectedProductTypeForAppointments"
                                :disabled="loadingCharts.timeAppointmentsData || isFetchingMainFilters">
                                <el-option :key="index" :value="item.value" :label="item.text"
                                    v-for="item, index in productList" />
                            </el-select>
                        </div>
                    </div>
                </vs-row>
                <div class="widget-chart" style="position: relative;  width: 100%; height: 100%;">
                    <div class="skeleton skeleton-chart-columns" style="
                            --cols: 10;
                            --col-w: 30px;
                            --col-gap: 20px;
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            margin: 0 auto;
                        " v-if="loadingCharts.timeAppointmentsData || isFetchingMainFilters"></div>
                    <template v-else>
                        <BarChart v-if="timeAppointmentsData.datasets.length" :height="230" :width="250"
                            :chartData="timeAppointmentsData" :chartOptions="timeAppointmentsDataOptions" />
                        <div v-else class="no-data-available">No Data Available</div>
                    </template>
                </div>
            </div>
            <div class="widget-container chart-widget"
                :style="{ justifyContent: 'flex-start', width: '100%', minHeight: '280px', paddingBottom: '10px' }">
                <!-- <div class="lds-dual-ring" v-if="loadingCharts.avgAppointmentData"></div> -->
                <vs-row vs-justify="space-between" style="margin-bottom: 15px;">
                    <div class="widget-title"
                        :style="{ display: 'flex', width: '100%', justifyContent: 'space-between' }">Average
                        Appointments Per Day ({{ selectedNurseName ? selectedNurseName : "Your Clinic" }} vs. High Volume
                        Nurses)

                        <QuestionTooltip>
                            <template slot="header">Average Appointments Per Day ({{ selectedNurseName ?
                                selectedNurseName : "Your Clinic" }} vs. High Volume Nurses)</template>
                            <template slot="body">Average number of Appointments tracked in the X per day</template>
                        </QuestionTooltip>
                    </div>
                    <div class="widget-options flex" style="justify-content: flex-end;">
                        <!-- <SearchAI title="" :btnIcon="true" /> -->
                        <!-- <vs-button :disabled="loadingCharts.avgAppointmentData" v-if="avgAppointmentData.labels.length" style="padding:5px 15px;" @click="exportAvgAppointmentsData(avgAppointmentData)">
                            Export
                        </vs-button> -->
                    </div>
                </vs-row>
                <div class="widget-chart avgAppointmentData" style="position: relative; width: 100%; height: 100%;">
                    <div class="skeleton skeleton-chart-columns" style="
                            --cols: 10;
                            --col-w: 30px;
                            --col-gap: 20px;
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            margin: 0 auto;
                        " v-if="loadingCharts.avgAppointmentData || isFetchingMainFilters"></div>
                    <template v-else>
                        <BarChart v-if="avgAppointmentData.datasets.length" :height="230" :width="250"
                            :chartData="avgAppointmentData" :chartOptions="avgAppointmentsDataOptions" />
                        <div v-else class="no-data-available">No Data Available</div>
                    </template>
                </div>
            </div>
        </vs-row>
        <vs-row vs-type="grid" class="grids-2">
            <div class="widget-container chart-widget" vs-justify="center"
                :style="{ width: '100%', minWidth: '100px', minHeight: '320px' }">
                <vs-row vs-justify="space-between">
                    <div class="widget-title"
                        :style="{ display: 'flex', width: '100%', justifyContent: 'space-between' }">
                        Patient Demographics

                        <QuestionTooltip>
                            <template slot="header">Patient Demographics</template>
                            <template slot="body">Demographic breakdown of your treated patients by age and
                                gender</template>
                        </QuestionTooltip>
                    </div>
                </vs-row>
                <div class="widget-chart" style="position: relative;  width: 100%; height: 100%;">
                    <vs-row vs-type="flex" vs-justify="end" class="insight-table-header">
                        <div class="dashboard-select full-label" style="width: 200px;">
                            <label class="select-label">Time Period</label>
                            <el-select popper-class="popper-select" v-model="selectedPatientTimePeriod"
                                :disabled="loadingCharts.patientDemographics || isFetchingMainFilters">
                                <el-option :key="item.value" :value="item.value" :label="item.text"
                                    v-for="item in timePeriodList" />
                            </el-select>
                        </div>
                    </vs-row>

                    <div class="skeleton skeleton-line" style="
                            --lines: 4;
                            --l-gap: 20px;
                            --c-w: 100%;
                            position: absolute;
                            top: 80x;
                            left: 0;
                            right: 0;
                            margin: 0 auto;
                        " v-if="loadingCharts.patientDemographics || isFetchingMainFilters"></div>
                    <template v-else>
                        <vs-table v-if="patientDemographics.length" class="insight-table-body"
                            :data="patientDemographics">
                            <template slot="thead">
                                <vs-th>Age</vs-th>
                                <vs-th>Female</vs-th>
                                <vs-th>Male</vs-th>
                                <vs-th class="th-wrapper" colspan="2">
                                    <vs-row vs-justify="center">Total</vs-row>
                                </vs-th>
                            </template>

                            <template slot-scope="{data}">
                                <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                                    <vs-td :data="data[indextr].age">
                                        {{ data[indextr].age }}
                                    </vs-td>

                                    <vs-td class="text-center" :data="data[indextr].female">
                                        {{ Math.trunc(data[indextr].female) }}%
                                    </vs-td>
                                    <vs-td class="text-center" :data="data[indextr].male">
                                        {{ Math.trunc(data[indextr].male) }}%
                                    </vs-td>

                                    <vs-td class="text-center divider-left" :data="data[indextr].totalCount">
                                        {{ data[indextr].totalCount }}
                                    </vs-td>
                                    <vs-td class="text-center" :data="data[indextr].totalPercentage">
                                        {{ Math.trunc(data[indextr].totalPercentage) }}%
                                    </vs-td>
                                </vs-tr>

                                <vs-tr class="total-row" key="patient-total-row">
                                    <vs-td :data="patientTotal.age">
                                        {{ patientTotal.age }}
                                    </vs-td>

                                    <vs-td class="text-center" :data="patientTotal.female">
                                        {{ Math.trunc(patientTotal.female) }}%
                                    </vs-td>
                                    <vs-td class="text-center" :data="patientTotal.male">
                                        {{ Math.trunc(patientTotal.male) }}%
                                    </vs-td>

                                    <vs-td class="text-center divider-left" :data="patientTotal.totalCount">
                                        {{ patientTotal.totalCount }}
                                    </vs-td>
                                    <vs-td class="text-center" :data="patientTotal.totalPercentage">
                                        {{ patientTotal.totalPercentage }}%
                                    </vs-td>
                                </vs-tr>
                            </template>
                        </vs-table>
                        <div v-else class="no-data-available">No Data Available</div>
                    </template>
                </div>
            </div>

            <div class="widget-container chart-widget" vs-justify="center"
                :style="{ width: '100%', minWidth: '100px', minHeight: '320px' }">
                <vs-row vs-justify="space-between">
                    <div class="widget-title">Average Treatment Areas per Appointments</div>
                </vs-row>
                <div class="widget-chart" style="position: relative;  width: 100%; height: 100%;">
                    <vs-row vs-type="flex" class="insight-table-header">
                        <div class="dashboard-select full-label" style="width: 250px;">
                            <label class="select-label">Product Type</label>
                            <el-select style="width: 100%;" popper-class="popper-select" v-model="selectedProductType"
                                :disabled="loadingCharts.avgTreatmentsPerAppointment || isFetchingMainFilters">
                                <el-option :key="index" :value="item.value" :label="item.text"
                                    v-for="item, index in productTypeList" />
                            </el-select>
                        </div>
                        <!-- <div class="dashboard-select full-label" style="width: 200px;">
                            <label class="select-label">Time Period</label>
                            <el-select
                                popper-class="popper-select"
                                v-model="selectedTreatmentTimePeriod"
                                :disabled="loadingCharts.avgTreatmentsPerAppointment || isFetchingMainFilters">
                                <el-option :key="index" :value="item.value" :label="item.text" v-for="item,index in timePeriodList" />
                            </el-select>
                        </div> -->
                    </vs-row>
                    <vs-table class="insight-table-body" :data="avgTreatmentsPerAppointment"
                        style="position: relative;  width: 100%; height: 100%;">
                        <template slot="thead">
                            <vs-th>Age</vs-th>
                            <vs-th class="th-wrapper" colspan="2">
                                <vs-row vs-justify="center" class="pb-1">Female</vs-row>
                                <vs-row>
                                    <div class="col2">You</div>
                                    <div class="col2">Comparable Clinics</div>
                                </vs-row>
                            </vs-th>
                            <vs-th class="th-wrapper" colspan="2">
                                <vs-row vs-justify="center" class="pb-1">Male</vs-row>
                                <vs-row>
                                    <div class="col2">You</div>
                                    <div class="col2">Comparable Clinics</div>
                                </vs-row>
                            </vs-th>
                        </template>

                        <template v-if="!loadingCharts.avgTreatmentsPerAppointment && !isFetchingMainFilters"
                            slot-scope="{data}">
                            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                                <vs-td :data="data[indextr].age">
                                    {{ data[indextr].age }}
                                </vs-td>

                                <vs-td class="text-center" :data="data[indextr].female">
                                    {{ (data[indextr].female) ? roundToDecimalPlaces(data[indextr].female, 1) : 0 }}
                                </vs-td>
                                <vs-td class="text-center" :data="data[indextr].femaleTop">
                                    {{ (data[indextr].femaleTop) ? roundToDecimalPlaces(data[indextr].femaleTop, 1) : 0
                                    }}
                                </vs-td>

                                <vs-td class="text-center" :data="data[indextr].male">
                                    {{ (data[indextr].male) ? roundToDecimalPlaces(data[indextr].male, 1) : 0 }}
                                </vs-td>
                                <vs-td class="text-center" :data="data[indextr].maleTop">
                                    {{ (data[indextr].maleTop) ? roundToDecimalPlaces(data[indextr].maleTop, 1) : 0 }}
                                </vs-td>
                            </vs-tr>

                            <vs-tr class="total-row" key="treatments-total-row">
                                <vs-td :data="treatmentsTotal.age">
                                    {{ treatmentsTotal.age }}
                                </vs-td>

                                <vs-td class="text-center" :data="treatmentsTotal.female">
                                    {{ treatmentsTotal.female }}
                                </vs-td>
                                <vs-td class="text-center" :data="treatmentsTotal.femaleTop">
                                    {{ treatmentsTotal.femaleTop }}
                                </vs-td>

                                <vs-td class="text-center" :data="treatmentsTotal.male">
                                    {{ treatmentsTotal.male }}
                                </vs-td>
                                <vs-td class="text-center" :data="treatmentsTotal.maleTop">
                                    {{ treatmentsTotal.maleTop }}
                                </vs-td>
                            </vs-tr>
                        </template>
                    </vs-table>
                    <div class="skeleton skeleton-line" style="
                            --lines: 3;
                            --l-gap: 20px;
                            --c-w: 100%;
                            position: absolute;
                            top: 130px;
                            left: 0;
                            right: 0;
                            margin: 0 auto;
                        " v-if="loadingCharts.avgTreatmentsPerAppointment || isFetchingMainFilters"></div>
                </div>
            </div>
        </vs-row>
    </div>
</template>
<script>
import utils from '@/assets/utils'
import SearchAI from '../../components/insights/common/SearchAI'
import BarChart from '../../components/insights/charts/BarChart.vue'
import { mapActions } from "vuex";
import moment from "moment";
import { uniqBy, sortBy, sumBy, find, filter, map, meanBy } from "lodash";
import QuestionTooltip from '../../components/insights/common/QuestionTooltip'
import PatientCountWidget from "../../components/insights/widgets/PatientCountWidget.vue";
import FeedbackWidget from "../../components/insights/widgets/FeedbackWidget.vue";

export default {
    props: {
        selectedNurse: {
            type: String,
            default: ''
        },
        selectedNurseName: {
            type: String,
            default: ''
        },
        selectedClinic: {
            type: Array,
            default: () => []
        },
        isFetchingMainFilters: {
            type: Boolean,
            default: false
        },
        selectedDate: {
            type: Array,
            default: () => []
        },
        loadingDropdownFilter: {
            type: Boolean,
            default: false
        },
        forceFetch: {
            type: Boolean,
            default: false
        },
        selectedConfiguration: {
            type: Object,
            default: () => { }
        }
    },
    components: {
        BarChart,
        SearchAI,
        QuestionTooltip,
        PatientCountWidget,
        FeedbackWidget
    },
    data() {
        return {
            enableWatchers: false,
            propWatchers: {},
            loadingCharts: {
                patientCount: false,
                timeAppointmentsData: false,
                avgAppointmentData: false,
                patientDemographics: false,
                avgTreatmentsPerAppointment: false
            },
            patientTotal: {
                "age": "Total",
                "female": 0,
                "male": 0,
                "totalCount": 0,
                "totalPercentage": 0
            },
            patientDemographics: [],
            selectedProductTypeForAppointments: 0,
            productList: [
                { value: 0, text: "Anti-wrinkle & Filler", param: "toxin_filler" },
                { value: 1, text: "Anti-wrinkle", param: "toxin" },
                { value: 2, text: "Filler", param: "filler" },
                { value: 3, text: "Other Injectables", param: "other_injectables" },
            ],
            selectedPatientTimePeriod: 1,
            timePeriodList: [
                { value: 1, text: "1 Year" },
                { value: 2, text: "2 Years" },
            ],
            selectedProductType: 1,
            productTypeList: [
                { value: 1, text: "Anti-wrinkle", param: "toxin" },
                { value: 2, text: "Filler", param: "filler" },
            ],
            selectedTreatmentTimePeriod: 1,
            treatmentsTotal: {
                "age": "Average",
                "female": 0,
                "femaleTop": 0,
                "male": 0,
                "maleTop": 0,
            },
            avgTreatmentsPerAppointment: [
            ],
            patientCountData: {
                "labels": [],
                "datasets": []
            },
            timeAppointmentsData: {
                "labels": [
                    "0-4 Months",
                    "4-8 Months",
                    "8-12 Months",
                    "12-16 Months",
                    "16+ Months"
                ],
                "datasets": []
            },
            timeAppointmentsDataOptions: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        pointStyleWidth: 14,
                        boxHeight: 10,
                        display: true,
                        position: 'bottom',
                        align: 'start',
                        labels: {
                            pointStyle: "circle",
                            usePointStyle: true,
                            padding: 20,
                            color: "#0E4333",
                            font: {
                                family: "InterMedium",
                                size: 12,
                            }
                        }
                    },
                    title: {
                        display: false,
                        text: ''
                    },
                    tooltip: {
                        caretSize: 0,
                        caretPadding: 15,
                        backgroundColor: 'rgba(0,0,0, 0.7)',
                        titleFont: {
                            family: 'InterSemiBold',
                            size: 16
                        },
                        bodySpacing: 3,
                        bodyFont: {
                            family: 'Inter',
                            size: 14
                        },
                        usePointStyle: true,
                        callbacks: {
                            labelColor: function (context) {
                                return {
                                    borderColor: context.dataset.backgroundColor,
                                    backgroundColor: context.dataset.backgroundColor,
                                };
                            },
                            labelPointStyle: function (context) {
                                return {
                                    pointStyle: 'circle',
                                };
                            }
                        },
                        boxWidth: 10,
                        boxHeight: 10,
                        boxPadding: 8,
                        borderWidth: 0,
                        padding: 15
                    }
                },
                scales: {
                    y: {
                        title: {
                            display: false,
                        },
                        ticks: {
                            color: "#0E4333",
                            font: {
                                family: "Inter",
                                size: 12,
                            },
                        },
                        grid: {
                            color: '#EFF1F4',
                            borderColor: '#EFF1F4',
                            tickColor: '#EFF1F4'
                        }
                    },
                    x: {
                        title: {
                            display: false,
                        },
                        grid: {
                            display: true,
                            color: '#EFF1F4'
                        },
                        ticks: {
                            color: "#0E4333",
                            font: {
                                family: "Inter",
                                size: 12,
                            },
                        }
                    }
                }
            },
            avgAppointmentData: {
                "labels": [
                    "Mon",
                    "Tue",
                    "Wed",
                    "Thu",
                    "Fri",
                    "Sat",
                    "Sun"
                ],
                "datasets": []
            },
            avgAppointmentsDataOptions: {
                interaction: {
                    intersect: false,
                    axis: 'x',
                },
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        pointStyleWidth: 14,
                        boxHeight: 10,
                        display: true,
                        position: 'bottom',
                        align: 'start',
                        labels: {
                            pointStyle: "circle",
                            usePointStyle: true,
                            padding: 20,
                            color: "#0E4333",
                            font: {
                                family: "InterMedium",
                                size: 12,
                            }
                        }
                    },
                    title: {
                        display: false,
                        text: ''
                    },
                    tooltip: {
                        mode: 'index',
                        caretSize: 0,
                        caretPadding: 15,
                        backgroundColor: 'rgba(0,0,0, 0.7)',
                        titleFont: {
                            family: 'InterSemiBold',
                            size: 16
                        },
                        bodySpacing: 3,
                        bodyFont: {
                            family: 'Inter',
                            size: 14
                        },
                        usePointStyle: true,
                        callbacks: {
                            labelColor: function (context) {
                                return {
                                    borderColor: context.dataset.backgroundColor,
                                    backgroundColor: context.dataset.backgroundColor,
                                };
                            },
                            labelPointStyle: function (context) {
                                return {
                                    pointStyle: 'circle',
                                };
                            },
                            label: function (context) {
                                let label = context.dataset.label || '';

                                if (label) {
                                    label += ': ' + Math.round(context.formattedValue);
                                }
                                return label;
                            }
                        },
                        boxWidth: 10,
                        boxHeight: 10,
                        boxPadding: 8,
                        borderWidth: 0,
                        padding: 15
                    },
                },
                scales: {
                    y: {
                        title: {
                            display: false,
                            text: ''
                        },
                        ticks: {
                            color: "#0E4333",
                            font: {
                                family: "Inter",
                                size: 12,
                            },
                        },
                        grid: {
                            color: '#EFF1F4',
                            borderColor: '#EFF1F4',
                            tickColor: '#EFF1F4'
                        }
                    },
                    x: {
                        title: {
                            display: false,
                        },
                        grid: {
                            display: true,
                            color: '#EFF1F4'
                        },
                        ticks: {
                            color: "#0E4333",
                            font: {
                                family: "Inter",
                                size: 12,
                            },
                        }
                    }
                }
            }
        }
    },
    mounted() {
        this.enableWatchers = false
        this.disablePropWatchers()
        this.fetchClinicPerformance()
        this.enableWatchers = true
    },
    methods: {
        moment(date) {
            return moment(date);
        },
        ...mapActions("analytics", [
            "fetchClinicPatientCount",
            "fetchClinicPatientDemographics",
            "fetchClinicAvgAppointmentsPerDayVsComparableClinics",
            "fetchClinicTimeBetweenAppointments",
            "fetchClinicAvgTreatmentsPerAppointmentComparableClinics"
        ]),
        disablePropWatchers() {
            this.propWatchers = { ...this.$options.watch }
            this.$options.watch = {}
        },
        enablePropWatchers() {
            this.$options.watch = { ...this.propWatchers }
        },
        exportPatientCount(chartData) {
            // let csv = "\ufeff";
            // let filename = `patient-count`
            // csv += "Date, Product Purchases ($)\n";

            // chartData.labels.forEach((date, index) => {
            //     csv += `${date},${chartData.datasets[0].data[index]}\n`;
            // });

            // utils.exportToCSV(csv, `${filename}${moment().format('YYMMDDHHmm')}`)
        },
        exportAvgAppointmentsData(chartData) {
            // let csv = "\ufeff";
            // let filename = `wrinkle-spend-breakdown`
            // csv += "Treatment, Number of treatments\n";

            // chartData.labels.forEach((label, index) => {
            //     csv += `${label},${chartData.datasets[0].data[index]}\n`;
            // });

            // utils.exportToCSV(csv, `${filename}${moment().format('YYMMDDHHmm')}`)
        },
        fetchClinicPatientCountData() {
            // Reset Chart
            this.patientCountData = {
                "labels": [],
                "datasets": []
            }

            const payload = {
                clinic: this.selectedClinic,
                nurse: this.selectedNurse ? [this.selectedNurse] : [],
            }
            this.loadingCharts.patientCount = true
            this.fetchClinicPatientCount(payload).then((res) => {
                if (res && res.data)
                    if (res.data.length)
                        this.initializePatientCountData(res.data)

                this.loadingCharts.patientCount = false;
            })
                .catch((err) => {
                    console.error("##ERROR: ", err.message);
                    this.loadingCharts.patientCount = false;
                });
        },
        initializePatientCountData(data) {
            const monthYear = data.map(({ trunc_month }) => `${this.moment(trunc_month.toString(), 'YYYY-MM-DD').format('MMM YYYY')}`)
            const newPatientsData = data.map(({ first_time_patients }) => first_time_patients)
            const returningPatientsData = data.map(({ returning_patients }) => returning_patients)


            const newPatients = newPatientsData.map((value, index) => {
                return {
                    x: returningPatientsData[index],
                    y: value
                }
            })

            const returningPatients = newPatientsData.map((value, index) => {
                return {
                    x: value,
                    y: returningPatientsData[index]
                }
            })

            this.patientCountData.labels = monthYear

            const hasPatientsData = newPatientsData.every(patientCount => patientCount !== null);
            const hasReturningPatientsData = returningPatientsData.every(patientCount => patientCount !== null);
            if (data && hasPatientsData && hasReturningPatientsData) {
                this.patientCountData.datasets = [
                    {
                        "label": "New Patients",
                        "data": newPatients,
                        "backgroundColor": "#236E55"
                    },
                    {
                        "label": "Returning Patients",
                        "data": returningPatients,
                        "backgroundColor": "#99E5DB"
                    }
                ]
            }
        },
        fetchClinicPatientDemographicsData() {
            // Reset Chart
            this.patientDemographics = []

            const dateNow = this.moment().format("YYYY-MM-DD")
            const startDate = this.moment().subtract(this.selectedPatientTimePeriod, 'year').format("YYYY-MM-DD");
            const endDate = dateNow;
            const payload = {
                clinic: this.selectedClinic,
                nurse: this.selectedNurse ? [this.selectedNurse] : [],
                start: startDate,
                end: endDate
            }
            this.loadingCharts.patientDemographics = true
            this.fetchClinicPatientDemographics(payload).then((res) => {
                if (res && res.data)
                    if (res.data.length)
                        this.initializePatientDemographicsData(res.data)

                this.loadingCharts.patientDemographics = false;
            })
                .catch((err) => {
                    console.error("##ERROR: ", err.message);
                    this.loadingCharts.patientDemographics = false;
                });
        },
        initializePatientDemographicsData(data) {
            const ageBracketsArray = uniqBy(data, 'age_bracket')
            const ageBrackets = ageBracketsArray.map(o => o.age_bracket)
            const patientDemographicsData = ageBrackets.map(ageBracket => {
                const maleData = find(data, function (o) { return o.age_bracket === ageBracket && o.gender === 'male' });
                const femaleData = find(data, function (o) { return o.age_bracket === ageBracket && o.gender === 'female' });
                const femalePercentdata = (femaleData) ? femaleData.percentage : 0
                const malePercentdata = (maleData) ? maleData.percentage : 0
                const femaleAppointmentsdata = (femaleData) ? femaleData.appointments : 0
                const maleAppointmentsdata = (maleData) ? maleData.appointments : 0

                return {
                    "age": ageBracket,
                    "female": this.roundToDecimalPlaces(femalePercentdata, 2),
                    "male": this.roundToDecimalPlaces(malePercentdata, 2),
                    "totalCount": (femaleData) ? this.roundToDecimalPlaces(femaleAppointmentsdata + maleAppointmentsdata, 2) : 0,
                    "totalPercentage": this.roundToDecimalPlaces(femalePercentdata + malePercentdata, 2),
                    "rawVal": {
                        "female": femalePercentdata,
                        "male": malePercentdata,
                        "totalCount": femaleAppointmentsdata + maleAppointmentsdata,
                        "totalPercentage": femalePercentdata + malePercentdata,
                    }
                }
            })
            this.patientDemographics = patientDemographicsData

            const sumFemale = patientDemographicsData.reduce((sum, entry) => sum + entry.rawVal.female, 0);
            const sumMale = patientDemographicsData.reduce((sum, entry) => sum + entry.rawVal.male, 0);
            const sumTotalPercentage = patientDemographicsData.reduce((sum, entry) => sum + entry.rawVal.totalPercentage, 0);

            const roundedFemale = this.roundToDecimalPlaces(sumFemale, 2);
            const roundedMale = this.roundToDecimalPlaces(sumMale, 2);
            const roundedTotalPercentage = this.roundToDecimalPlaces(sumTotalPercentage, 2);

            this.patientTotal = {
                "age": "Total",
                "female": roundedFemale,
                "male": roundedMale,
                "totalCount": patientDemographicsData.reduce((sum, entry) => sum + entry.rawVal.totalCount, 0),
                "totalPercentage": roundedTotalPercentage
            };
        },
        roundToDecimalPlaces(num, decimalPlaces) {
            const multiplier = 10 ** decimalPlaces;
            return Math.round(num * multiplier) / multiplier;
        },
        fetchClinicAvgAppointmentsPerDayData() {
            console.log('patients', this.selectedConfiguration);
            // Reset Chart
            this.avgAppointmentData.datasets = []

            const payload = {
                clinic: this.selectedClinic,
                nurse: this.selectedNurse ? [this.selectedNurse] : [],
                start: this.moment(this.selectedDate[0]).format("YYYY-MM-DD"),
                end: this.moment(this.selectedDate[1]).format("YYYY-MM-DD"),
                isHighPerformers: this.selectedConfiguration.isHighPerformers,
                selectedGeo: this.selectedConfiguration.selectedGeo,
                selectedInjector: this.selectedConfiguration.selectedInjector
            }
            this.loadingCharts.avgAppointmentData = true

            this.$emit('disableConfig', 'avgAppointmentData', true);

            this.fetchClinicAvgAppointmentsPerDayVsComparableClinics(payload).then((res) => {
                if (res && res.data)
                    if (res.data.length)
                        this.initializeAvgAppointmentsPerDayData(res.data)

                this.loadingCharts.avgAppointmentData = false;

                this.$emit('disableConfig', 'avgAppointmentData', false);
            })
                .catch((err) => {
                    console.error("##ERROR: ", err.message);
                    this.loadingCharts.avgAppointmentData = false;

                    this.$emit('disableConfig', 'avgAppointmentData', false);
                });
        },
        initializeAvgAppointmentsPerDayData(data) {
            const self = this;

            const dayIndex = {
                'Monday': 1,
                'Tuesday': 2,
                'Wednesday': 3,
                'Thursday': 4,
                'Friday': 5,
                'Saturday': 6,
                'Sunday': 7
            };

            data.sort((a, b) => {
                return dayIndex[a.day_name] - dayIndex[b.day_name];
            });

            // get all selected clinic data
            let selectedClinicData = filter(data, function (o) { return o.clinic_type === "Your Clinic" });
            const topClinicData = filter(data, function (o) { return o.clinic_type !== "Your Clinic" });

            if (topClinicData.length > selectedClinicData.length) {
                selectedClinicData = map(topClinicData, function (o) {
                    const val = find(selectedClinicData, function (selectedData) { return o.day_name == selectedData.day_name; });
                    return (val) ? val : {
                        avg_daily_appointments: 0,
                        clinic_type: o.clinic_type,
                        day_name: o.day_name
                    }
                })

                selectedClinicData.sort((a, b) => {
                    return dayIndex[a.day_name] - dayIndex[b.day_name];
                });
            }


            const appointmentX = selectedClinicData.map(({ avg_daily_appointments }) => avg_daily_appointments)
            const appointmentY = topClinicData.map(({ avg_daily_appointments }) => avg_daily_appointments)

            const selectedClinicDataAppointments = appointmentX.map((value, index) => {
                return {
                    x: appointmentY[index],
                    y: value
                }
            })

            const topClinicDataAppointments = appointmentX.map((value, index) => {
                return {
                    x: value,
                    y: appointmentY[index]
                }
            })

            this.avgAppointmentData.datasets = [{
                "label": this.selectedNurseName ? this.selectedNurseName : "Your Clinic",
                "data": selectedClinicDataAppointments,
                "backgroundColor": "#236E55"
            }, {
                "label": "High Volume Nurses",
                "data": topClinicDataAppointments,
                "backgroundColor": "#99E5DB"
            }]
        },
        fetchClinicTimeBetweenAppointmentsData() {
            // Reset Chart
            this.timeAppointmentsData = {
                "labels": [],
                "datasets": []
            }

            const selectedTreatmentType = find(this.productList, { value: this.selectedProductTypeForAppointments })
            const payload = {
                clinic: this.selectedClinic,
                nurse: this.selectedNurse ? [this.selectedNurse] : [],
                treatment_type: selectedTreatmentType.param
            }

            this.loadingCharts.timeAppointmentsData = true
            this.fetchClinicTimeBetweenAppointments(payload).then((res) => {
                if (res && res.data)
                    if (res.data.length)
                        this.initializeTimeBetweenAppointmentsData(res.data)

                this.loadingCharts.timeAppointmentsData = false;
            })
                .catch((err) => {
                    console.error("##ERROR: ", err.message);
                    this.loadingCharts.timeAppointmentsData = false;
                });
        },
        initializeTimeBetweenAppointmentsData(data) {
            const isAllZero = data.every(o => o.patient_count === 0);
            if (data && !isAllZero) {
                this.timeAppointmentsData = {
                    labels: data.map(o => o.date_range),
                    datasets: [
                        {
                            "label": "Patient Count",
                            "data": data.map(o => o.patient_count),
                            "backgroundColor": "#236E55"
                        }
                    ]
                }
            }
        },
        fetchClinicAvgTreatmentsPerAppointmentData() {
            console.log('patients', this.selectedConfiguration);
            const selectedProductType = find(this.productTypeList, { value: this.selectedProductType })
            const dateNow = this.moment().format("YYYY-MM-DD")
            const startDate = this.moment().subtract(this.selectedTreatmentTimePeriod, 'year').format("YYYY-MM-DD");
            const endDate = dateNow;
            const payload = {
                clinic: this.selectedClinic,
                nurse: this.selectedNurse ? [this.selectedNurse] : [],
                treatment_type: selectedProductType.param,
                start: startDate,
                end: endDate,
                isHighPerformers: this.selectedConfiguration.isHighPerformers,
                selectedGeo: this.selectedConfiguration.selectedGeo,
                selectedInjector: this.selectedConfiguration.selectedInjector
            }

            this.loadingCharts.avgTreatmentsPerAppointment = true

            this.$emit('disableConfig', 'avgTreatmentsPerAppointment', true);

            this.fetchClinicAvgTreatmentsPerAppointmentComparableClinics(payload).then((res) => {
                if (res && res.data)
                    if (res.data.length)
                        this.initializeAvgTreatmentsPerAppointmentData(res.data)

                this.loadingCharts.avgTreatmentsPerAppointment = false;

                this.$emit('disableConfig', 'avgTreatmentsPerAppointment', false);
            })
                .catch((err) => {
                    console.error("##ERROR: ", err.message);
                    this.loadingCharts.avgTreatmentsPerAppointment = false;

                    this.$emit('disableConfig', 'avgTreatmentsPerAppointment', false);
                });
        },
        initializeAvgTreatmentsPerAppointmentData(data) {
            const ageBracketsArray = uniqBy(data, 'age_bracket')
            const sortedAgeBrackets = sortBy(ageBracketsArray, ({ age_bracket }) => age_bracket === '18 below' ? 0 : 1);
            const ageBrackets = sortedAgeBrackets.map(o => o.age_bracket)

            const avgTreatmentsPerAppointmentData = ageBrackets.map(ageBracket => {
                const femaleData = find(data, function (o) { return o.age_bracket === ageBracket && o.gender === 'female' && o.clinic_type === 'Your Clinic' });
                const femaleTopData = find(data, function (o) { return o.age_bracket === ageBracket && o.gender === 'female' && o.clinic_type !== 'Your Clinic' });
                const maleData = find(data, function (o) { return o.age_bracket === ageBracket && o.gender === 'male' && o.clinic_type === 'Your Clinic' });
                const maleTopData = find(data, function (o) { return o.age_bracket === ageBracket && o.gender === 'male' && o.clinic_type !== 'Your Clinic' });

                return {
                    "age": ageBracket,
                    "female": (femaleData) ? femaleData.treatment_count : 0,
                    "femaleTop": (femaleTopData) ? femaleTopData.treatment_count : 0,
                    "male": (maleData) ? maleData.treatment_count : 0,
                    "maleTop": (maleTopData) ? maleTopData.treatment_count : 0,
                }
            })
            this.avgTreatmentsPerAppointment = avgTreatmentsPerAppointmentData

            this.treatmentsTotal = {
                "age": "Average",
                "female": this.roundToDecimalPlaces(meanBy(avgTreatmentsPerAppointmentData, function (o) { return o.female; }).toFixed(2), 1),
                "femaleTop": this.roundToDecimalPlaces(meanBy(avgTreatmentsPerAppointmentData, function (o) { return o.femaleTop; }).toFixed(2), 1),
                "male": this.roundToDecimalPlaces(meanBy(avgTreatmentsPerAppointmentData, function (o) { return o.male; }).toFixed(2), 1),
                "maleTop": this.roundToDecimalPlaces(meanBy(avgTreatmentsPerAppointmentData, function (o) { return o.maleTop; }).toFixed(2), 1),
            }
        },
        fetchClinicPerformance() {
            if (this.selectedClinic.length) {
                if (!this.loadingCharts.patientCount) {
                    this.fetchClinicPatientCountData()
                }

                if (!this.loadingCharts.patientDemographics) {
                    this.fetchClinicPatientDemographicsData()
                }

                if (!this.loadingCharts.avgAppointmentData) {
                    this.fetchClinicAvgAppointmentsPerDayData()
                }

                if (!this.loadingCharts.timeAppointmentsData) {
                    this.fetchClinicTimeBetweenAppointmentsData()
                }

                if (!this.loadingCharts.avgTreatmentsPerAppointment) {
                    this.fetchClinicAvgTreatmentsPerAppointmentData()
                }
            }
        },
    },
    watch: {
        loadingDropdownFilter: {
            handler(newVal) {
                this.loadingCharts.avgAppointmentData = newVal
                this.loadingCharts.avgTreatmentsPerAppointment = newVal
                this.loadingCharts.patientCount = newVal
                this.loadingCharts.patientDemographics = newVal
                this.loadingCharts.timeAppointmentsData = newVal
            },
            immediate: true,
        },
        forceFetch: {
            handler(newVal) {
                if (newVal) {
                    this.fetchClinicPerformance();

                    this.$emit('resetForceFetch');
                }
            }
        },
        enableWatchers: {
            handler(newVal) {
                this.enablePropWatchers()
            },
            immediate: true,
        },
        selectedNurse: {
            handler(newVal) {
                if (!this.loadingDropdownFilter)
                    this.fetchClinicPerformance()
            },
            immediate: true,
        },
        selectedClinic: {
            handler(newVal) {
                if (!this.loadingDropdownFilter)
                    this.fetchClinicPerformance()
            },
            immediate: true,
        },
        selectedDate: {
            handler(newVal) {
                if (newVal.length) {
                    if (!this.loadingDropdownFilter) {
                        this.fetchClinicPerformance()
                    }
                }
            },
            immediate: true,
        },
        selectedPatientTimePeriod: {
            handler(newVal) {
                if (this.selectedClinic) {
                    this.fetchClinicPatientDemographicsData()
                }
            },
            immediate: true,
        },
        selectedProductTypeForAppointments: {
            handler(newVal) {
                if (this.selectedClinic) {
                    this.fetchClinicTimeBetweenAppointmentsData()
                }
            },
            immediate: true,
        },
        selectedProductType: {
            handler(newVal) {
                if (this.selectedClinic) {
                    this.fetchClinicAvgTreatmentsPerAppointmentData()
                }
            },
            immediate: false,
        },
        // selectedTreatmentTimePeriod: {
        //     handler(newVal) {
        //         if (this.selectedClinic) {
        //             this.fetchClinicAvgTreatmentsPerAppointmentData()
        //         }
        //     },
        //     immediate: true,
        // }
    },
}
</script>
<style>
#clinic-dashboard-patients .chart-widget {
    padding: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.grids-2 {
    /* grid-template-columns: 1fr 1fr; */
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 12px;
    margin-bottom: 12px;
}

#clinic-dashboard-patients .insight-table-body .vs-table {
    text-align: center;
}

#clinic-dashboard-patients .insight-table-body th .vs-table-text {
    justify-content: center;
}

#clinic-dashboard-patients .divider-left {
    border-left: 1px solid #C9DED8;
}

#clinic-dashboard-patients .full-label label {
    width: 180px;
}

#clinic-dashboard-patients .total-row td span {
    border-top: 1px solid #C9DED8;
    padding: 5px 30px;
    font-family: 'InterBold';
}

.insight-widget-header {
    border-radius: 15px 15px 0 0;
    gap: 10px;
    bottom: 6px;
    position: relative;
}

.insight-widget-header .con-select,
.insight-widget-header .dashboard-select {
    display: flex;
    gap: 10px;
    align-items: center;
}

.insight-widget-header .vs-select--label,
.insight-widget-header .select-label {
    color: #37695B;
    font-family: 'InterSemiBold';
    font-size: 12px;
    width: 90px !important;
}

@media only screen and (max-width: 1068px) {
    .grids-2 {
        display: block !important;
    }
}
</style>
